import client, { returnResponse } from './client';

const { post } = client;

const donate = async (masjidID, donation, suffix) =>
    returnResponse(await post(`/portal/donations/${masjidID}/${suffix}`, donation));

const singleDonate = (masjidID, donation) => donate(masjidID, donation, 'single');

const recurringDonate = (masjidID, donation) => donate(masjidID, donation, 'recurring');

export { singleDonate, recurringDonate };
