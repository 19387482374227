import format from 'sugar/number/format';
import capitalize from 'sugar/string/capitalize';
import spacify from 'sugar/string/spacify';

const currencify = amount => `$${amount}`;

const formatAmount = (amount, isCents = true) =>
    amount <= 0.5 ? 'FREE' : currencify(format(amount / (isCents ? 100 : 1), 2));

const humanize = string => capitalize(spacify(string), true, true);

const getQueryParam = name => new URLSearchParams(window.location.search).get(name);

export { humanize, formatAmount, getQueryParam };
