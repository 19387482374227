import { createSelector } from 'reselect';
import { DateUtils } from '../../utils';
import { getProgramID } from '../../utils/location';

const selectCategories = ({
    masjid: {
        data: { Categories }
    }
}) => Categories;

const selectClasses = ({
    masjid: {
        data: { Classes }
    }
}) => Classes;
const selectEvents = ({
    masjid: {
        data: { Events }
    }
}) => Events;

export const getCategories = createSelector(
    selectCategories,
    categories =>
        categories
            .filter(({ Enabled }) => Enabled)
            .map(({ CategoryName, ID, RecurringEnabled }) => ({
                label: CategoryName,
                value: `${ID}`,
                recurringEnabled: RecurringEnabled
            }))
);

const mapPrograms = programs =>
    programs.map(
        ({
            ID,
            Title,
            Description,
            RegistrationDeadline,
            StartDate,
            EndDate,
            ChargeFrequency,
            RegisteredAttendeeCount,
            IsEditable,
            ShownOnKioskAndPortal,
            CancelledAt,
            Cost,
            City,
            State,
            Zipcode,
            HouseNumber,
            Days,
            Street
        }) => ({
            id: ID,
            ID,
            Description,
            Title,
            ChargeFrequency,
            StartDate: DateUtils.format(StartDate, 'MM/DD/YYYY'),
            EndDate: DateUtils.format(EndDate, 'MM/DD/YYYY'),
            RegistrationDeadline: DateUtils.format(RegistrationDeadline, 'MM/DD/YYYY'),
            ShowOnKiosk: ShownOnKioskAndPortal ? 'Yes' : 'No',
            ShownOnKioskAndPortal,
            RegisteredAttendeeCount,
            IsEditable,
            Cancelled: !!CancelledAt,
            Cost,
            City,
            State,
            Zipcode,
            HouseNumber,
            Days,
            Street
        })
    );

const getProgramFromID = (classes, events, programID) => {
    const {
        ID,
        Title,
        Description,
        RegistrationDeadline,
        StartDate,
        EndDate,
        ChargeFrequency,
        RegisteredAttendeeCount,
        IsEditable,
        CancelledAt,
        ShownOnKioskAndPortal,
        RemainingCapacity,
        ProgramState,
        Cost,
        City,
        State,
        Type,
        Zipcode,
        HouseNumber,
        Days,
        Street,
        FathersNameApplicable,
        MothersNameApplicable,
        EmergencyContactNameApplicable,
        EmergencyContactNumberApplicable,
        FathersNameRequired,
        MothersNameRequired,
        EmergencyContactNameRequired,
        EmergencyContactNumberRequired
    } =
        classes.find(program => program.ID === +programID) ||
        events.find(program => program.ID === +programID);
    return {
        id: ID,
        ID,
        Description,
        Title,
        StartDate: DateUtils.format(StartDate, 'MM/DD/YYYY'),
        EndDate: DateUtils.format(EndDate, 'MM/DD/YYYY'),
        ChargeFrequency,
        RegistrationDeadline: DateUtils.format(RegistrationDeadline, 'MM/DD/YYYY'),
        ShowOnKiosk: ShownOnKioskAndPortal ? 'Yes' : 'No',
        RemainingCapacity,
        ProgramState,
        RegisteredAttendeeCount,
        IsEditable,
        Cancelled: !!CancelledAt,
        Cost,
        City,
        State,
        Zipcode,
        HouseNumber,
        Days,
        Street,
        Type,
        FathersNameApplicable,
        MothersNameApplicable,
        EmergencyContactNameApplicable,
        EmergencyContactNumberApplicable,
        FathersNameRequired,
        MothersNameRequired,
        EmergencyContactNameRequired,
        EmergencyContactNumberRequired
    };
};

export const getClasses = createSelector(
    selectClasses,
    mapPrograms
);

export const getEvents = createSelector(
    selectEvents,
    mapPrograms
);

export const getActiveClasses = createSelector(
    getClasses,
    classes => classes.filter(c => c.ShownOnKioskAndPortal)
);

export const getActiveEvents = createSelector(
    getEvents,
    events => events.filter(c => c.ShownOnKioskAndPortal)
);

export const getProgram = createSelector(
    [selectClasses, selectEvents, getProgramID],
    getProgramFromID
);
