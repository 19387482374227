import { useState, useEffect, useRef } from 'react';

function useDebounce(f, delay) {
    const initialRef = useRef(null);
    const [value, setValue] = useState(initialRef);

    useEffect(() => {
        const handler = setTimeout(() => {
            // This will prevent the function to be called
            // with the intial timeout if setValue isn't called till then.
            if (value !== initialRef) {
                f(value);
            }
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return setValue;
}

export default useDebounce;
