import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Fade } from 'reactstrap';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Components, store } from 'musalleen-react-theme';
import { Motion, spring } from 'react-motion';
import { PageHeader } from '../../shared/components';
import { useForm, useBoolean } from '../../hooks';
import { getCategories } from '../../store/selectors';
import { StringUtils, ScreenUtils } from '../../utils';
import { DonationAPI, Constants } from '../../api';

const { InfoCard, FormInput, PaymentWidget, Button, Loader } = Components;
const {
    showSuccessAlert,
    showNoInternetAlert,
    showServerErrorAlert,
    showBadRequestAlert
} = store.actions;

const HAS_NO_DONATION_CATEGORES = 'Has no donation categories.';
const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}/;
function Donation({
    categories,
    hasCategories,
    stripePublishableApiKey,
    masjidID,
    nonProfit501C3Approved,
    masjidName,
    taxId,
    donationsEnabled,
    hasOnlineDonationsAddOn,
    hasOnlineRecurringDonationsAddOn,
    hasWebsiteBase,
    dispatch
}) {
    const fromWebsite = StringUtils.getQueryParam('fromWebsite') || false;
    const [values, errors, handleInputChange, setError, validate, resetForm] = useForm(
        {
            email: StringUtils.getQueryParam('email') || '',
            category: hasCategories
                ? StringUtils.getQueryParam('category') || categories[0].value
                : '',
            amount: StringUtils.getQueryParam('amount') || '',
            recurring: StringUtils.getQueryParam('recurring') === 'true',
            recurringMonths: StringUtils.getQueryParam('recurringMonths') || ''
        },
        ['category', 'amount', 'email']
    );

    const [paymentWidgetVisible, showPaymentWidget, hidePaymentWidget] = useBoolean(fromWebsite);
    const [successScreenVisible, showSuccessScreen] = useBoolean(false);
    const [processingPayment, processPayment, doneProcessingPayment] = useBoolean(false);

    useEffect(() => {
        if (!hasOnlineDonationsAddOn && !hasWebsiteBase) {
            // If the Masjid doesn't have the online donations addon.
            browserHistory.replace('/404');
        }
    }, []);

    function onContinue() {
        const formHasErrors = validate();
        if (formHasErrors) {
            return;
        }
        const { email, amount, recurring, recurringMonths } = values;
        if (+amount <= 0.5) {
            setError('amount', 'Amount has to be greater than 0.5.');
            return;
        }
        if (doesCurrentCategoryHaveRecurring() && recurring && +recurringMonths <= 1) {
            setError('recurringMonths', 'Months have to be greater than 1.');
            return;
        }
        if (!emailRegex.test(email.trim())) {
            setError('email', 'Email is not valid.');
            return;
        }
        showPaymentWidget();
    }

    async function onPayment(token, { brand, last4, name }) {
        processPayment();
        const { email, amount, recurring, category, recurringMonths } = values;
        const payload = {
            Amount: +amount * 100,
            StripeToken: token,
            FirstName: name,
            LastName: '',
            CategoryID: category,
            Email: email.trim(),
            Last4: last4,
            Brand: brand
        };
        const { error, originalError } =
            doesCurrentCategoryHaveRecurring() && recurring
                ? await DonationAPI.recurringDonate(masjidID, {
                      ...payload,
                      MonthsRecurring: +recurringMonths
                  })
                : await DonationAPI.singleDonate(masjidID, payload);
        doneProcessingPayment();
        if (!fromWebsite) {
            hidePaymentWidget();
        }
        if (error) {
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert(originalError));
                return;
            }
            if (error === Constants.CLIENT_ERROR) {
                dispatch(showBadRequestAlert(originalError));
                return;
            }
            dispatch(showNoInternetAlert(originalError));
            return;
        }

        if (fromWebsite) {
            // Show Success Screen
            showSuccessScreen();
        } else {
            dispatch(showSuccessAlert('You have successfully donated to this Masjid!'));
            resetForm();
        }
    }

    function doesCurrentCategoryHaveRecurring() {
        return (
            hasCategories &&
            categories.find(({ value }) => value === values.category).recurringEnabled
        );
    }

    function renderDonationDetails() {
        return (
            <InfoCard
                title="Enter donation details"
                subTitle="Fill out the information below to complete your donation."
                showFooter={false}
            >
                <Row>
                    <div className="col-md-12">
                        <FormInput
                            type="select"
                            label="Category"
                            value={values.category}
                            options={hasCategories ? categories : [HAS_NO_DONATION_CATEGORES]}
                            state={errors.category ? 'danger' : 'default'}
                            message={errors.category}
                            onChangeValue={handleInputChange('category')}
                            disabled={!hasCategories}
                        />
                        <FormInput
                            type="number"
                            label="Amount"
                            value={values.amount}
                            state={errors.amount ? 'danger' : 'default'}
                            message={errors.amount}
                            min={0}
                            max={9999}
                            onChangeValue={handleInputChange('amount')}
                            placeholder="Amount you want to donate."
                        />
                        <Fade
                            in={
                                doesCurrentCategoryHaveRecurring() &&
                                (hasWebsiteBase || hasOnlineRecurringDonationsAddOn)
                            }
                            mountOnEnter
                            unmountOnExit
                        >
                            <Fragment>
                                <FormInput
                                    type="checkbox"
                                    label="Recurring&nbsp;&nbsp;"
                                    value={values.recurring}
                                    state={errors.recurring ? 'danger' : 'default'}
                                    message={errors.recurring}
                                    onChangeValue={handleInputChange('recurring')}
                                />

                                <Fade in={values.recurring} mountOnEnter unmountOnExit>
                                    <FormInput
                                        type="number"
                                        label="Recurring Months"
                                        value={values.recurringMonths}
                                        state={errors.recurringMonths ? 'danger' : 'default'}
                                        message={errors.recurringMonths}
                                        min={0}
                                        max={1200}
                                        onChangeValue={handleInputChange('recurringMonths')}
                                        placeholder="Months this donation runs for."
                                        disabled={!values.recurring}
                                    />
                                </Fade>
                            </Fragment>
                        </Fade>
                        <FormInput
                            label="Email"
                            value={values.email}
                            state={errors.email ? 'danger' : 'default'}
                            message={errors.email}
                            onChangeValue={handleInputChange('email')}
                            placeholder="Email for receipt."
                        />
                        <Button onClick={onContinue} disabled={!donationsEnabled}>
                            {donationsEnabled ? 'Continue' : 'Donations are currently disabled'}
                        </Button>
                    </div>
                </Row>
            </InfoCard>
        );
    }

    function renderCardWidget() {
        return (
            <Fragment>
                {!successScreenVisible && (
                    <InfoCard
                        title="Enter card details"
                        subTitle="Fill out the information below to complete your donation."
                        showFooter={false}
                    >
                        <Row>
                            <div className="col-md-12">
                                <Fade in={!processingPayment}>
                                    <PaymentWidget
                                        show
                                        mode="collapse"
                                        onPayment={onPayment}
                                        onClose={hidePaymentWidget}
                                        closeOnPayment={false}
                                        apiKey={stripePublishableApiKey}
                                        totalAmount={StringUtils.formatAmount(values.amount, false)}
                                    />
                                </Fade>
                                <Fade in={processingPayment}>
                                    <div className="processing-payment-container widget-container d-flex justify-content-center align-items-center">
                                        <Loader thin height={100} />
                                    </div>
                                </Fade>
                            </div>
                        </Row>
                    </InfoCard>
                )}
                {successScreenVisible && (
                    <InfoCard title="Payment Status" subTitle="" showFooter={false}>
                        <Row>
                            <div className="col-md-12">
                                <div
                                    style={{ height: '100px' }}
                                    className="d-flex justify-content-center"
                                >
                                    <div className="d-flex flex-column align-items-center">
                                        <h3 className="text-center">Thanks!</h3>
                                        <h3 className="text-center mt-2">
                                            Your donation was successful!
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </InfoCard>
                )}
            </Fragment>
        );
    }

    return (
        <div className="view">
            <PageHeader
                title="Donation"
                description={`${masjidName} is an IRS recognized ${
                    nonProfit501C3Approved ? '501-c3' : ''
                } non-profit organization ${taxId ? `and our tax ID number is ${taxId}` : ``}`}
            />
            <div className="view-content">
                <Row>
                    <div className="offset-md-3 col-md-6">
                        <Motion
                            style={{
                                x: spring(
                                    !paymentWidgetVisible ? 0 : -ScreenUtils.getWindowWidth(),
                                    { stiffness: 60, damping: 11 }
                                )
                            }}
                        >
                            {({ x }) => (
                                <div
                                    className="widget-container"
                                    style={{
                                        WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                        transform: `translate3d(${x}px, 0, 0)`
                                    }}
                                >
                                    {renderDonationDetails()}
                                </div>
                            )}
                        </Motion>
                        <Motion
                            style={{
                                x: spring(paymentWidgetVisible ? 0 : ScreenUtils.getWindowWidth(), {
                                    stiffness: 60,
                                    damping: 11
                                })
                            }}
                        >
                            {({ x }) =>
                                paymentWidgetVisible && (
                                    <div
                                        className="widget-container"
                                        style={{
                                            WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                            transform: `translate3d(${x}px, 0, 0)`
                                        }}
                                    >
                                        {renderCardWidget()}
                                    </div>
                                )
                            }
                        </Motion>
                    </div>
                </Row>
            </div>
        </div>
    );
}
Donation.defaultProps = {
    taxId: '',
    hasWebsiteBase: false
};

Donation.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            recurringEnabled: PropTypes.bool.isRequired
        })
    ).isRequired,
    hasCategories: PropTypes.bool.isRequired,
    stripePublishableApiKey: PropTypes.string.isRequired,
    masjidID: PropTypes.number.isRequired,
    masjidName: PropTypes.string.isRequired,
    nonProfit501C3Approved: PropTypes.string.isRequired,
    taxId: PropTypes.string,
    donationsEnabled: PropTypes.bool.isRequired,
    hasOnlineRecurringDonationsAddOn: PropTypes.bool.isRequired,
    hasOnlineDonationsAddOn: PropTypes.bool.isRequired,
    hasWebsiteBase: PropTypes.bool,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    categories: getCategories(state),
    hasCategories: !!getCategories(state).length,
    stripePublishableApiKey: state.masjid.data.StripePublishableApiKey,
    masjidID: state.masjid.data.ID,
    masjidName: state.masjid.data.Name,
    nonProfit501C3Approved: state.masjid.data.NonProfit501C3Approved,
    taxId: state.masjid.data.TaxId,
    donationsEnabled: state.masjid.data.DonationsEnabled,
    hasOnlineRecurringDonationsAddOn: state.masjid.data.HasOnlineRecurringDonationsAddOn,
    hasOnlineDonationsAddOn: state.masjid.data.HasOnlineDonationsAddOn,
    hasWebsiteBase: state.masjid.data.HasWebsiteBase
});

export default connect(mapStateToProps)(Donation);
