import { store } from 'musalleen-react-theme';
import createActionCreator from './create';
import { SET_MASJID, REFRESHING_MASJID } from '../types';
import { MasjidAPI, Constants } from '../../api';

const { showNoInternetAlert, showServerErrorAlert } = store.actions;

const setMasjid = createActionCreator(SET_MASJID, 'masjid');
const setMasjidRefreshing = createActionCreator(REFRESHING_MASJID, 'refreshing');

export const refreshMasjid = masjidID => async (dispatch, getState) => {
    const {
        masjid: { updatedAt, interval }
    } = getState();
    if (new Date().getTime() <= updatedAt + interval) {
        // This case indicates the data is not stale enough
        // and it has been fetched recently.
        // We don't need to do anything now.
        return null;
    }

    // To indicate we're currently refreshing data.
    dispatch(setMasjidRefreshing(true));

    // Refresh masjid data.
    const { error, data } = await MasjidAPI.getMasjid(masjidID);

    if (error) {
        if (error === Constants.CLIENT_ERROR) {
            // Let the UI handle this since the masjidID is invalid.
            return error;
        }
        if (error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
            return error;
        }
        dispatch(showNoInternetAlert());
        return error;
    }

    // Store Masjid's data.
    dispatch(setMasjid(data));

    // We're done refreshing data.
    dispatch(setMasjidRefreshing(false));

    return null;
};
