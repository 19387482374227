import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Components } from 'musalleen-react-theme';
import { browserHistory } from 'react-router';
import 'musalleen-react-theme/lib/theme/theme.scss';
import { AppHeader } from './shared/components';
import { refreshMasjid } from './store/actions';
import './App.scss';
import { LocationUtils } from './utils';
import { Constants } from './api';

const { Loader } = Components;

function App({ dispatch, refreshingMasjid, masjidIconUrl, children }) {
    useEffect(() => {
        // Refresh Masjid's data in case we need to.
        refresh();
        // Do it on every page change except for 404.
        const historyUnlisten = browserHistory.listen(({ pathname }) => {
            if (!pathname.includes('404')) {
                refresh();
            }
        });

        // Stop listening when component unmounts.
        return () => {
            historyUnlisten();
        };
    }, []);

    async function refresh() {
        const masjidID = LocationUtils.getMasjidID();
        // Show a 404 when url doesn't contain a masjidID.
        if (!masjidID) {
            browserHistory.replace('/404');
            return;
        }
        // Refresh Masjid's data in case we need to.
        const error = await dispatch(refreshMasjid(masjidID));
        if (error === Constants.CLIENT_ERROR) {
            // If an invalid masjidID supplied was invalid, show a 404 instead.
            // Other errors are handled by the action creator itself.
            browserHistory.replace('/404');
        }
    }

    if (refreshingMasjid) {
        return (
            <div className="app-wrapper">
                <div className="loader-container">
                    <Loader height={200} thin />
                </div>
            </div>
        );
    }
    return (
        <div
            className="content-container full-width"
            style={{
                backgroundImage: masjidIconUrl ? `url("${masjidIconUrl}")` : null
            }}
        >
            <AppHeader />
            <div>{children}</div>
            <div className="full-width-overlay" />
        </div>
    );
}

App.propTypes = {
    refreshingMasjid: PropTypes.bool.isRequired,
    masjidIconUrl: PropTypes.string,
    children: PropTypes.element.isRequired,
    dispatch: PropTypes.func.isRequired
};

App.defaultProps = {
    masjidIconUrl: null
};

const mapStateToProps = state => ({
    // Display a loading state only when there is no stale data
    // and the masjid data is refreshing. (i.e It is fresh page load)
    refreshingMasjid: state.masjid.refreshing && !state.masjid.updatedAt,
    masjidIconUrl: state.masjid.data.MasjidIconUrl
});

export default connect(
    mapStateToProps,
    null,
    null,
    {
        areStatesEqual: (nextState, prevState) =>
            nextState.masjid.refreshing === prevState.masjid.refreshing &&
            nextState.masjid.updatedAt === prevState.masjid.updatedAt
    }
)(App);
