import { create, SERVER_ERROR, CLIENT_ERROR, CONNECTION_ERROR, NETWORK_ERROR } from 'apisauce';

const url = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const client = create({ baseURL: `${url}/api` });

const Constants = {
    SERVER_ERROR,
    CLIENT_ERROR,
    CONNECTION_ERROR,
    NETWORK_ERROR
};

const returnResponse = ({ ok, problem, data, status, originalError }) => ({
    error: !ok ? problem : null,
    data,
    status,
    originalError: !ok ? new Error(`${originalError}. Response - ${JSON.stringify(data)}`) : null
});

export { Constants, returnResponse };

export default client;
