import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconMail from 'react-icons/lib/io/android-mail';
import IconGlobe from 'react-icons/lib/io/android-globe';
import IconCall from 'react-icons/lib/io/android-call';
import './style.scss';

function AppHeader({ Name, Email, WebsiteUrl, PhoneNumber }) {
    return (
        <header className="site-head d-flex align-items-center justify-content-between">
            <div className="masjid-name">
                <strong className="h3 text-uppercase">{Name}</strong>
            </div>
            <div className="right-elems ml-auto d-flex">
                <div className="wrap hidden-sm-down text-white">
                    <a
                        href={`mailto:${Email}`}
                        className="links"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <IconMail />
                        &nbsp;
                        {Email}
                    </a>
                    &nbsp; &nbsp;
                    <span>
                        {PhoneNumber && <IconCall />}
                        &nbsp;
                        {PhoneNumber}
                    </span>
                    <br />
                    <a
                        href={WebsiteUrl}
                        className="links"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <IconGlobe />
                        &nbsp;
                        {WebsiteUrl}
                    </a>
                    <br />
                </div>
            </div>
        </header>
    );
}

const mapStateToProps = ({
    masjid: {
        data: { Name, Email, WebsiteUrl, PhoneNumber }
    }
}) => ({
    Name,
    Email,
    WebsiteUrl,
    PhoneNumber
});

AppHeader.propTypes = {
    Name: PropTypes.string.isRequired,
    Email: PropTypes.string.isRequired,
    WebsiteUrl: PropTypes.string.isRequired,
    PhoneNumber: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(AppHeader);
