const getCurrentEnvironment = () => {
    switch (window.location.host) {
        case 'musalleen-portal-develop.herokuapp.com':
            return 'develop';
        case 'musalleen-portal-stage.herokuapp.com':
            return 'stage';
        case 'portal.musalleen.com':
            return 'production';
        default:
            return 'development';
    }
};

const isRunningInDevelopmentMode = () => getCurrentEnvironment() === 'development';

export { getCurrentEnvironment, isRunningInDevelopmentMode };
