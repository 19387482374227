import formatDate from 'date-fns/format';
import parseDate from 'date-fns-2/parse';
import addMonths from 'date-fns/add_months';
import addYears from 'date-fns/add_years';

const days = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday'
};

const format = (date, dateFormat = 'MM/DD/YYYY') => formatDate(date, dateFormat);

const parse = (dateString, dateFormat) =>
    parseDate(dateString, dateFormat.replace(/D/g, 'd').replace(/Y/g, 'y'), new Date());

const weekDayName = day => days[day];

export { format, parse, weekDayName, addMonths, addYears };
