import React, { Fragment } from 'react';
import { Router, Route, Redirect, browserHistory } from 'react-router';
import { AnalyticsUtils } from './utils';
import App from './App';
import { Donation } from './screens/donation';
import { Page404 } from './screens/404';
import { Program, Programs } from './screens/programs';
// import { Home } from './screens/home';

const routes = (
    <Fragment>
        <Route path="/masjid/:id" component={App}>
            <Route path="donation" component={Donation} />
            <Route path="programs" component={Programs} />
            <Route path="programs/:id" component={Program} />
        </Route>
        {/* Auxillary routes outside App. */}
        <Route path="404" component={Page404} />
        <Redirect from="*" to="404" />
    </Fragment>
);

AnalyticsUtils.listenForPageChanges(browserHistory);

export default () => <Router history={browserHistory} routes={routes} />;
