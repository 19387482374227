// Get masjidID from url.
// pathname = "portal.dashboard.com/masjid/2/xyz"
// returns = "2"
const getMasjidID = () => {
    const split = window.location.pathname.split('masjid/');
    return split.length > 1 ? split[1].split('/')[0] : null;
};

const getProgramID = () => {
    const split = window.location.pathname.split('programs/');
    return split.length > 1 ? split[1].split('/')[0] : null;
};

export { getMasjidID, getProgramID };
