import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Components } from 'musalleen-react-theme';
import { PageHeader } from '../../shared/components';
import { getActiveClasses, getActiveEvents } from '../../store/selectors';

import './index.scss';
import { StringUtils } from '../../utils';

const { InfoCard } = Components;

function Programs({
    classes,
    events,
    masjidID,
    hasOnlineClassRegistrationAddOn,
    hasOnlineEventRegistrationAddOn,
    hasWebsiteBase
}) {
    useEffect(() => {
        if (
            !hasWebsiteBase &&
            !hasOnlineClassRegistrationAddOn &&
            !hasOnlineEventRegistrationAddOn
        ) {
            browserHistory.replace('404');
        }
    }, []);

    function viewProgram(id, programs) {
        return function() {
            const program = programs.find(c => c.ID === id);
            browserHistory.push({
                pathname: `/masjid/${masjidID}/programs/${program.ID}`,
                state: program.ID
            });
        };
    }

    function renderPrograms() {
        return (
            <Fragment>
                {(hasOnlineClassRegistrationAddOn || hasWebsiteBase) && (
                    <InfoCard
                        title="Classes"
                        subTitle={
                            classes.length
                                ? 'Here are all the classes events of this Masjid.'
                                : 'No events available.'
                        }
                        showFooter={false}
                    >
                        <Row>
                            {classes.map(({ ID, Title, Cost, ChargeFrequency }) => (
                                <div key={ID} className="mb-4 col-md-3">
                                    <Card
                                        onClick={viewProgram(ID, classes)}
                                        className="table-row-clickable"
                                    >
                                        <CardBody>
                                            <Row>
                                                <div className="col-md-12 h5">{Title}</div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-12">
                                                    {`${StringUtils.formatAmount(
                                                        Cost,
                                                        false
                                                    )} - ${ChargeFrequency}`}
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </Row>
                    </InfoCard>
                )}
                {(hasOnlineEventRegistrationAddOn || hasWebsiteBase) && (
                    <InfoCard
                        title="Events"
                        subTitle={
                            events.length
                                ? 'Here are all the active events of this Masjid.'
                                : 'No events available.'
                        }
                        showFooter={false}
                    >
                        <Row>
                            {events.map(({ ID, Title, Cost }) => (
                                <div key={ID} className="mb-4 col-md-3">
                                    <Card
                                        onClick={viewProgram(ID, events)}
                                        className="table-row-clickable"
                                    >
                                        <CardBody>
                                            <Row>
                                                <div className="col-md-12 h5">{Title}</div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-12">
                                                    {StringUtils.formatAmount(Cost, false)}
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </Row>
                    </InfoCard>
                )}
            </Fragment>
        );
    }
    return (
        <div className="view">
            <PageHeader
                title="Classes &amp; Events"
                description="Register for Classes and Events of this Masjid."
            />
            <div className="view-content">
                <Row>
                    <div className="offset-md-2 col-md-8">
                        <div className="widget-container">{renderPrograms()}</div>
                    </div>
                </Row>
            </div>
        </div>
    );
}

Programs.propTypes = {
    classes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    masjidID: PropTypes.number.isRequired,
    hasOnlineClassRegistrationAddOn: PropTypes.bool.isRequired,
    hasOnlineEventRegistrationAddOn: PropTypes.bool.isRequired,
    hasWebsiteBase: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    classes: getActiveClasses(state),
    events: getActiveEvents(state),
    masjidID: state.masjid.data.ID,
    stripePublishableApiKey: state.masjid.data.StripePublishableApiKey,
    hasOnlineClassRegistrationAddOn: state.masjid.data.HasOnlineClassRegistrationAddOn,
    hasOnlineEventRegistrationAddOn: state.masjid.data.HasOnlineEventRegistrationAddOn,
    hasWebsiteBase: state.masjid.data.HasWebsiteBase
});

export default connect(mapStateToProps)(Programs);
