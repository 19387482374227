import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Fade, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Components, store } from 'musalleen-react-theme';
import { Motion, spring } from 'react-motion';
import { PageHeader } from '../../shared/components';
import { useBoolean } from '../../hooks';
import { ScreenUtils, DateUtils, StringUtils, ProgramUtils } from '../../utils';
import { ProgramAPI, Constants } from '../../api';
import { getProgram } from '../../store/selectors';
import './index.scss';
import { getMasjidID } from '../../utils/location';

const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}/;
const phoneRegex = /([0-9]){8,17}/;

const { InfoCard, FormInput, PaymentWidget, Button, Loader, DescriptionList, Image } = Components;
const {
    showSuccessAlert,
    showNoInternetAlert,
    showServerErrorAlert,
    showBadRequestAlert,
    showDangerAlert
} = store.actions;

function Program({
    dispatch,
    program: {
        ID,
        Title,
        Description,
        ImageUrl,
        StartDate,
        EndDate,
        ChargeFrequency,
        RegistrationDeadline,
        Cost,
        HouseNumber,
        City,
        Street,
        Type,
        State,
        Zipcode,
        Days,
        RemainingCapacity,
        ProgramState,
        FathersNameApplicable,
        MothersNameApplicable,
        EmergencyContactNameApplicable,
        EmergencyContactNumberApplicable,
        FathersNameRequired,
        MothersNameRequired,
        EmergencyContactNameRequired,
        EmergencyContactNumberRequired
    },
    enablePayByCash,
    stripePublishableApiKey,
    hasOnlineClassRegistrationAddOn,
    hasOnlineEventRegistrationAddOn,
    hasWebsiteBase
}) {
    const [paymentWidgetVisible, showPaymentWidget, hidePaymentWidget] = useBoolean(false);
    const [processingPayment, processPayment, doneProcessingPayment] = useBoolean(false);
    const [enrolling, enrollingStudents, doneEnrollingStudents] = useBoolean(false);
    const registrationForm = useRef(null);

    const registrationInterface = {
        Email: '',
        PhoneNumber: '',
        Attendees: [{ FirstName: '', LastName: '', Age: '', Gender: '' }]
    };
    const [registrationValidation, setRegistrationValidation] = useState({
        ...registrationInterface
    });

    const [currentRegistration, setCurrentRegistration] = useState({
        ...registrationInterface,
        Brand: '',
        Last4: '',
        StripeToken: '',
        PayByCash: false,
        PaymentMode: 'Other',
        Attendees: [
            {
                FirstName: '',
                LastName: '',
                Age: '',
                Gender: 'Male',
                FathersName: '',
                MothersName: '',
                EmergencyContactName: '',
                EmergencyContactNumber: ''
            }
        ]
    });

    useEffect(() => {
        if (
            !hasWebsiteBase &&
            !hasOnlineClassRegistrationAddOn &&
            !hasOnlineEventRegistrationAddOn
        ) {
            browserHistory.replace('404');
        }
    }, []);

    async function onPayment(token, { brand, last4, name }) {
        processPayment();
        const payload = {
            ...currentRegistration,
            StripeToken: token,
            PaymentMode: 'Card',
            FirstNameOnCard: name,
            LastNameOnCard: '',
            Last4: last4,
            Brand: brand
        };
        const { error, originalError } = await ProgramAPI.register(ID, payload);
        hidePaymentWidget();
        doneProcessingPayment();
        if (error) {
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert(originalError));
                return;
            }
            if (error === Constants.CLIENT_ERROR) {
                dispatch(showBadRequestAlert(originalError));
                return;
            }
            dispatch(showNoInternetAlert(originalError));
            return;
        }
        resetForm();
        dispatch(showSuccessAlert('You have successfully registered!'));
        navigateToPrograms();
    }
    function handleRegistrationPropertyChange(column) {
        return function(value) {
            setCurrentRegistration({ ...currentRegistration, [column]: value });
        };
    }

    async function registerStudents() {
        enrollingStudents();
        const { error, originalError } = await ProgramAPI.register(ID, currentRegistration);
        doneEnrollingStudents();
        if (error) {
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert(originalError));
                return;
            }
            if (error === Constants.CLIENT_ERROR) {
                dispatch(showBadRequestAlert(originalError));
                return;
            }
            dispatch(showNoInternetAlert(originalError));
            return;
        }
        resetForm();
        dispatch(showSuccessAlert('You have successfully registered!'));
        navigateToPrograms();
    }

    function navigateToPrograms() {
        const masjidID = getMasjidID();
        browserHistory.push({
            pathname: `/masjid/${masjidID}/programs`,
            state: masjidID
        });
    }

    function handleAttendeePropertyChange(column, index) {
        return function(value) {
            setCurrentRegistration({
                ...currentRegistration,
                Attendees: currentRegistration.Attendees.map((attendee, attendeeIndex) => {
                    if (attendeeIndex === index) {
                        return { ...attendee, [column]: value };
                    }
                    return attendee;
                })
            });
        };
    }

    function resetForm() {
        setCurrentRegistration({
            ...registrationInterface,
            Brand: '',
            Last4: '',
            StripeToken: '',
            PayByCash: false,
            PaymentMode: 'Other',
            Attendees: [
                {
                    FirstName: '',
                    LastName: '',
                    Age: '',
                    Gender: 'Male',
                    FathersName: '',
                    MothersName: '',
                    EmergencyContactName: '',
                    EmergencyContactNumber: ''
                }
            ]
        });
    }

    function onContinue() {
        let validationError = false;
        const newRegistrationValidation = {
            ...registrationInterface,
            Attendees: Array(currentRegistration.Attendees.length).fill({
                FirstName: '',
                LastName: '',
                Age: '',
                Gender: '',
                FathersName: '',
                MothersName: '',
                EmergencyContactName: '',
                EmergencyContactNumber: ''
            })
        };
        for (const i in currentRegistration.Attendees) {
            const attendee = currentRegistration.Attendees[i];
            for (const [attendeKey, attendeValue] of Object.entries(attendee)) {
                if (!attendeValue) {
                    newRegistrationValidation.Attendees = newRegistrationValidation.Attendees.map(
                        /*eslint-disable-line*/(invalidAttendee, index) => {
                            if (+i === index) {
                                if (
                                    (attendeKey === 'FathersName' && !FathersNameRequired) ||
                                    (attendeKey === 'MothersName' && !MothersNameRequired) ||
                                    (attendeKey === 'EmergencyContactName' &&
                                        !EmergencyContactNameRequired) ||
                                    (attendeKey === 'EmergencyContactNumber' &&
                                        !EmergencyContactNumberRequired)
                                ) {
                                    return invalidAttendee;
                                }

                                validationError = true;
                                return {
                                    ...invalidAttendee,
                                    [attendeKey]: `${attendeKey} can't be empty`
                                };
                            }

                            return invalidAttendee;
                        }
                    );
                }
            }
        }

        for (const key of Object.keys(registrationInterface)) {
            if (!currentRegistration[key]) {
                validationError = true;
                newRegistrationValidation[key] = `${key} can't be empty`;
            }
        }

        if (currentRegistration.PhoneNumber && !phoneRegex.test(currentRegistration.PhoneNumber)) {
            validationError = true;
            newRegistrationValidation.PhoneNumber =
                'Please enter a valid phone number. Without any symbols or alphabets';
        }
        if (currentRegistration.Email && !emailRegex.test(currentRegistration.Email.trim())) {
            validationError = true;
            newRegistrationValidation.Email = 'Email is not valid';
        }
        setRegistrationValidation(newRegistrationValidation);
        if (validationError) {
            dispatch(
                showDangerAlert(
                    'You have errors in the form above that need to be fixed before you can register.'
                )
            );
            return;
        }
        if (Cost <= 0.5 || currentRegistration.PayByCash) {
            registerStudents();
            return;
        }

        showPaymentWidget();
    }

    function addAnotherRegistration() {
        if (RemainingCapacity <= currentRegistration.Attendees.length) {
            dispatch(
                showDangerAlert(
                    'Cannot add more attendees as the capacity for this program is now exhausted.'
                )
            );
            return;
        }
        setCurrentRegistration({
            ...currentRegistration,
            Attendees: [
                ...currentRegistration.Attendees,
                {
                    FirstName: '',
                    LastName: '',
                    Age: '',
                    Gender: 'Male',
                    FathersName: currentRegistration.Attendees[0].FathersName || '',
                    MothersName: currentRegistration.Attendees[0].MothersName || '',
                    EmergencyContactName:
                        currentRegistration.Attendees[0].EmergencyContactName || '',
                    EmergencyContactNumber:
                        currentRegistration.Attendees[0].EmergencyContactNumber || ''
                }
            ]
        });
        setRegistrationValidation({
            ...registrationValidation,
            Attendees: [
                ...registrationValidation.Attendees,
                {
                    FirstName: '',
                    LastName: '',
                    Age: '',
                    Gender: '',
                    FathersName: '',
                    MothersName: '',
                    EmergencyContactName: '',
                    EmergencyContactNumber: ''
                }
            ]
        });
    }

    function onChangePayByCash() {
        setCurrentRegistration({
            ...currentRegistration,
            PayByCash: !currentRegistration.PayByCash
        });
    }
    function renderCardWidget() {
        return (
            <InfoCard
                title="Enter card details"
                subTitle="Fill out the information below to complete your payment."
                showFooter={false}
            >
                <Row>
                    <div className="col-md-12">
                        <Fade in={!processingPayment}>
                            <PaymentWidget
                                show
                                mode="collapse"
                                onPayment={onPayment}
                                onClose={hidePaymentWidget}
                                closeOnPayment={false}
                                apiKey={stripePublishableApiKey}
                                totalAmount={StringUtils.formatAmount(
                                    Cost * currentRegistration.Attendees.length,
                                    false
                                )}
                            />
                        </Fade>
                        <Fade in={processingPayment}>
                            <div className="processing-payment-container widget-container d-flex justify-content-center align-items-center">
                                <Loader thin height={100} />
                            </div>
                        </Fade>
                    </div>
                </Row>
            </InfoCard>
        );
    }
    function renderProgramRegistrationForm() {
        return (
            <Fragment>
                <InfoCard
                    title={`${Title} (${ProgramState})`}
                    subTitle={Description}
                    showFooter={false}
                >
                    {ImageUrl && (
                        <DescriptionList
                            list={[
                                {
                                    label: 'Image',
                                    value: (
                                        <Image
                                            title="Program Flyer"
                                            enableEdit={false}
                                            url={ImageUrl}
                                        />
                                    )
                                }
                            ]}
                            containerClassName="col-md-12 mt-4"
                        />
                    )}
                    <DescriptionList
                        list={[
                            {
                                label: 'Cost',
                                value: `${StringUtils.formatAmount(
                                    Cost,
                                    false
                                )} - ${ChargeFrequency}`
                            },
                            {
                                label: 'Registration Deadline',
                                value: RegistrationDeadline
                            },
                            { label: 'Start Date - End Date', value: `${StartDate} - ${EndDate}` },
                            {
                                label: 'Address',
                                value: `${HouseNumber}, ${Street}, ${City}, ${State}, ${Zipcode}`
                            }
                        ]}
                        containerClassName="col-md-12 mt-4"
                    />
                    <hr />
                    <Row>
                        <div ref={registrationForm} className="col-md-6">
                            <FormInput
                                type="email"
                                label="Email"
                                value={currentRegistration.Email}
                                state={registrationValidation.Email ? 'danger' : 'default'}
                                message={registrationValidation.Email}
                                onChangeValue={handleRegistrationPropertyChange('Email')}
                                placeholder="Email for registration"
                            />
                        </div>
                        <div className="col-md-6">
                            <FormInput
                                type="tel"
                                label="Phone Number"
                                value={currentRegistration.PhoneNumber}
                                state={registrationValidation.PhoneNumber ? 'danger' : 'default'}
                                message={registrationValidation.PhoneNumber}
                                min={0}
                                max={9999999999}
                                onChangeValue={handleRegistrationPropertyChange('PhoneNumber')}
                                placeholder="Phone Number for registration"
                            />
                        </div>
                    </Row>
                    {currentRegistration.Attendees.map((attendee, index) => (
                        <Row key={`registration-${index}` /* eslint-disable-line */}>
                            <div className="col-md-3">
                                <FormInput
                                    type="text"
                                    label="First Name"
                                    value={attendee.FirstName}
                                    state={
                                        !(
                                            registrationValidation.Attendees[index] &&
                                            registrationValidation.Attendees[index].FirstName
                                        )
                                            ? 'default'
                                            : 'danger'
                                    }
                                    message={
                                        registrationValidation.Attendees[index]
                                            ? registrationValidation.Attendees[index].FirstName
                                            : ''
                                    }
                                    onChangeValue={handleAttendeePropertyChange('FirstName', index)}
                                    placeholder="First Name"
                                />
                            </div>
                            <div className="col-md-3">
                                <FormInput
                                    type="text"
                                    label="Last Name"
                                    value={attendee.LastName}
                                    state={
                                        !(
                                            registrationValidation.Attendees[index] &&
                                            registrationValidation.Attendees[index].LastName
                                        )
                                            ? 'default'
                                            : 'danger'
                                    }
                                    message={
                                        registrationValidation.Attendees[index]
                                            ? registrationValidation.Attendees[index].LastName
                                            : ''
                                    }
                                    onChangeValue={handleAttendeePropertyChange('LastName', index)}
                                    placeholder="Last Name"
                                />
                            </div>
                            <div className="col-md-3">
                                <FormInput
                                    type="number"
                                    label="Age"
                                    value={attendee.Age}
                                    state={
                                        !(
                                            registrationValidation.Attendees[index] &&
                                            registrationValidation.Attendees[index].Age
                                        )
                                            ? 'default'
                                            : 'danger'
                                    }
                                    message={
                                        registrationValidation.Attendees[index]
                                            ? registrationValidation.Attendees[index].Age
                                            : ''
                                    }
                                    min={0}
                                    max={1000}
                                    onChangeValue={handleAttendeePropertyChange('Age', index)}
                                    placeholder="Age"
                                />
                            </div>
                            <div className="col-md-3">
                                <FormInput
                                    type="select"
                                    label="Gender"
                                    value={attendee.Gender}
                                    options={[
                                        { label: 'Male', value: 'Male' },
                                        { label: 'Female', value: 'Female' }
                                    ]}
                                    onChangeValue={handleAttendeePropertyChange('Gender', index)}
                                />
                            </div>
                            {FathersNameApplicable && (
                                <div className="col-md-3">
                                    <FormInput
                                        type="text"
                                        label="Father's Name"
                                        value={attendee.FathersName}
                                        state={
                                            !(
                                                registrationValidation.Attendees[index] &&
                                                registrationValidation.Attendees[index].FathersName
                                            )
                                                ? 'default'
                                                : 'danger'
                                        }
                                        message={
                                            registrationValidation.Attendees[index]
                                                ? registrationValidation.Attendees[index]
                                                      .FathersName
                                                : ''
                                        }
                                        onChangeValue={handleAttendeePropertyChange(
                                            'FathersName',
                                            index
                                        )}
                                        placeholder="Father's Name"
                                    />
                                </div>
                            )}
                            {MothersNameApplicable && (
                                <div className="col-md-3">
                                    <FormInput
                                        type="text"
                                        label="Mother's Name"
                                        value={attendee.MothersName}
                                        state={
                                            !(
                                                registrationValidation.Attendees[index] &&
                                                registrationValidation.Attendees[index].MothersName
                                            )
                                                ? 'default'
                                                : 'danger'
                                        }
                                        message={
                                            registrationValidation.Attendees[index]
                                                ? registrationValidation.Attendees[index]
                                                      .MothersName
                                                : ''
                                        }
                                        onChangeValue={handleAttendeePropertyChange(
                                            'MothersName',
                                            index
                                        )}
                                        placeholder="Mother's Name"
                                    />
                                </div>
                            )}
                            {EmergencyContactNameApplicable && (
                                <div className="col-md-3">
                                    <FormInput
                                        type="text"
                                        label="Emergency Contact Name"
                                        value={attendee.EmergencyContactName}
                                        state={
                                            !(
                                                registrationValidation.Attendees[index] &&
                                                registrationValidation.Attendees[index]
                                                    .EmergencyContactName
                                            )
                                                ? 'default'
                                                : 'danger'
                                        }
                                        message={
                                            registrationValidation.Attendees[index]
                                                ? registrationValidation.Attendees[index]
                                                      .EmergencyContactName
                                                : ''
                                        }
                                        onChangeValue={handleAttendeePropertyChange(
                                            'EmergencyContactName',
                                            index
                                        )}
                                        placeholder="Emergency Contact Name"
                                    />
                                </div>
                            )}
                            {EmergencyContactNumberApplicable && (
                                <div className="col-md-3">
                                    <FormInput
                                        type="number"
                                        label="Emergency Contact Number"
                                        value={attendee.EmergencyContactNumber}
                                        state={
                                            !(
                                                registrationValidation.Attendees[index] &&
                                                registrationValidation.Attendees[index]
                                                    .EmergencyContactNumber
                                            )
                                                ? 'default'
                                                : 'danger'
                                        }
                                        min={0}
                                        max={9999999999}
                                        message={
                                            registrationValidation.Attendees[index]
                                                ? registrationValidation.Attendees[index]
                                                      .EmergencyContactNumber
                                                : ''
                                        }
                                        onChangeValue={handleAttendeePropertyChange(
                                            'EmergencyContactNumber',
                                            index
                                        )}
                                        placeholder="Emergency Contact Number"
                                    />
                                </div>
                            )}
                        </Row>
                    ))}
                    {Type === 'Class' && ChargeFrequency !== 'Onetime' && (
                        <div className="text-info mb-3">
                            ${Cost} will be deducted right now and the next ${Cost} will deducted
                            again on{' '}
                            {ChargeFrequency === 'Monthly'
                                ? DateUtils.format(DateUtils.addMonths(StartDate, 1))
                                : DateUtils.format(DateUtils.addYears(StartDate, 1))}{' '}
                            and so on.
                        </div>
                    )}
                    {enablePayByCash && ChargeFrequency === 'Onetime' && (
                        <Row className="mb-2">
                            <div className="col-md-12">
                                <FormGroup className="float-right">
                                    <Label>
                                        <h6>
                                            <Input
                                                type="checkbox"
                                                checked={currentRegistration.PayByCash}
                                                onChange={onChangePayByCash}
                                            />
                                            &nbsp; Pay by Cash
                                        </h6>
                                    </Label>
                                </FormGroup>
                            </div>
                        </Row>
                    )}
                    <Row>
                        <div className="col-md-12">
                            <div className="float-left">
                                <Button onClick={addAnotherRegistration}>
                                    Add Another {`${Type === 'Class' ? 'Student' : 'Participant'}`}
                                </Button>
                            </div>
                            <div className="float-right">
                                <Button
                                    loading={enrolling}
                                    onClick={onContinue}
                                    disabled={!ProgramUtils.isRegistrationOpen(ProgramState)}
                                >
                                    {ProgramUtils.isRegistrationOpen(ProgramState)
                                        ? 'Continue'
                                        : 'Registration Closed'}
                                </Button>
                            </div>
                        </div>
                    </Row>
                </InfoCard>
                <InfoCard
                    title="Schedule"
                    subTitle="Days on which program takes place."
                    showFooter={false}
                    showButtonInHeader={false}
                >
                    {Days.map(day => (
                        <DescriptionList
                            containerClassName="mt-4"
                            key={`day-readonly-${day.Day}`}
                            list={[
                                { label: 'Day', value: DateUtils.weekDayName(day.Day) },
                                {
                                    label: 'Start Time',
                                    value: DateUtils.format(
                                        DateUtils.parse(day.StartTime, 'HH:mm:ss'),
                                        'hh:mm A'
                                    )
                                },
                                {
                                    label: 'End Time',
                                    value: DateUtils.format(
                                        DateUtils.parse(day.EndTime, 'HH:mm:ss'),
                                        'hh:mm A'
                                    )
                                }
                            ]}
                        />
                    ))}
                    <hr />
                </InfoCard>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="view">
                <PageHeader
                    title={`${Title} (${ProgramState})`}
                    description={`Register for ${Title}.`}
                />
                <div className="view-content">
                    <Row>
                        <div className="offset-md-2 col-md-8">
                            <Motion
                                style={{
                                    x: spring(
                                        !paymentWidgetVisible ? 0 : -ScreenUtils.getWindowWidth(),
                                        {
                                            stiffness: 60,
                                            damping: 11
                                        }
                                    )
                                }}
                            >
                                {({ x }) =>
                                    !paymentWidgetVisible && (
                                        <div
                                            className="widget-container"
                                            style={{
                                                WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                                transform: `translate3d(${x}px, 0, 0)`
                                            }}
                                        >
                                            {renderProgramRegistrationForm()}
                                        </div>
                                    )
                                }
                            </Motion>
                            <Motion
                                style={{
                                    x: spring(
                                        paymentWidgetVisible ? 0 : ScreenUtils.getWindowWidth(),
                                        {
                                            stiffness: 60,
                                            damping: 11
                                        }
                                    )
                                }}
                            >
                                {({ x }) =>
                                    paymentWidgetVisible && (
                                        <div
                                            className="widget-container"
                                            style={{
                                                WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                                transform: `translate3d(${x}px, 0, 0)`
                                            }}
                                        >
                                            {renderCardWidget()}
                                        </div>
                                    )
                                }
                            </Motion>
                        </div>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
}

Program.propTypes = {
    program: PropTypes.shape({}).isRequired,
    enablePayByCash: PropTypes.bool.isRequired,
    stripePublishableApiKey: PropTypes.string.isRequired,
    hasOnlineClassRegistrationAddOn: PropTypes.bool.isRequired,
    hasOnlineEventRegistrationAddOn: PropTypes.bool.isRequired,
    hasWebsiteBase: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    program: getProgram(state),
    enablePayByCash: state.masjid.data.EnablePayByCash,
    stripePublishableApiKey: state.masjid.data.StripePublishableApiKey,
    hasOnlineClassRegistrationAddOn: state.masjid.data.HasOnlineClassRegistrationAddOn,
    hasOnlineEventRegistrationAddOn: state.masjid.data.HasOnlineEventRegistrationAddOn,
    hasWebsiteBase: state.masjid.data.HasWebsiteBase
});

export default connect(mapStateToProps)(Program);
