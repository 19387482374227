import React from 'react';
import PropTypes from 'prop-types';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || 'c134ab62989e3b44fdb18d3f50edffd5',
    notifyReleaseStages: ['production', 'stage', 'develop'],
    releaseStage: process.env.REACT_APP_BUGSNAG_STAGE
});

bugsnagClient.use(bugsnagReact, React);
const Boundary = bugsnagClient.getPlugin('react');

const setBugsnagUser = (name, email, masjidID, masjidName) => {
    bugsnagClient.user = {
        name,
        email
    };
    bugsnagClient.metaData = {
        masjid: {
            id: masjidID,
            name: masjidName
        }
    };
};

const removeBugsnagUser = () => {
    bugsnagClient.user = null;
    bugsnagClient.metaData = null;
};

function ErrorBoundary({ children }) {
    return <Boundary>{children}</Boundary>;
}

ErrorBoundary.propTypes = {
    children: PropTypes.element.isRequired
};

export { setBugsnagUser, removeBugsnagUser };
export default ErrorBoundary;
