import { produce } from 'immer';
import { SET_MASJID, REFRESHING_MASJID } from '../types';
import { EnvironmentUtils } from '../../utils';

// Time after which masjid data will go stale and
// a new API call will be made for each refreshMasjid action.
// Otherwise no API call is made. (seconds)
const REFRESH_INTERVAL = EnvironmentUtils.isRunningInDevelopmentMode() ? 10 : 30;

const INITIAL_STATE = {
    data: {},
    updatedAt: 0,
    interval: REFRESH_INTERVAL * 1000,
    refreshing: true
};

/* eslint-disable no-param-reassign */
const masjid = produce((draft, { type, payload }) => {
    switch (type) {
        case SET_MASJID: {
            draft.data = payload.masjid;
            draft.updatedAt = new Date().getTime();
            break;
        }
        case REFRESHING_MASJID: {
            draft.refreshing = payload.refreshing;
            break;
        }
        default:
    }
    return draft;
}, INITIAL_STATE);
/* eslint-enable */

export default masjid;
